import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import logo from "assets/img/logo.png";
import home from "assets/img/contact-img.png";
import playStore from "assets/img/play-store.webp";
const RootLayout = () => {
  const [isShown, setIsShown] = useState(false);
  const [openedAccordion, setOpenedAccordion] = useState("");
  const handleAccordion = (accordion) => {
    if (window.innerWidth < 768) {
      setOpenedAccordion((prev) => (prev === accordion ? "" : accordion));
    }
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsShown(false);
  }, [pathname]);
  return (
    <>
      <header>
        <div className="header-top">
          <div className="container">
            <div className="row">
              <a href="tel:+91-8879135850">
                <i className="bi bi-telephone text-primary"></i> +91-8879135850 / 9920312575
              </a>
              <a href="mailto:jagahdekhoo@gmail.com">
                <i className="bi bi-envelope text-primary"></i> jagahdekhoo@gmail.com
              </a>
              <span className="ms-auto d-block">
                <i className="bi bi-clock text-primary"></i> Mon to Sat - 09:00 to 21:00
              </span>
              <span>MAHARERA: A51800046201</span>
            </div>
          </div>
        </div>
        <div className="main-header">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <Link to="/" className="logo">
                <img src={logo} alt="logo" />
              </Link>
              <ul className={`navbar ${isShown ? "shown" : ""}`}>
                <li>
                  <Link to="/" className={pathname === "/" ? "active" : ""}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/properties" className={pathname === "/properties" ? "active" : ""}>
                    Properties
                  </Link>
                </li>
                <li>
                  <Link to="/projects" className={pathname === "/projects" ? "active" : ""}>
                    Projects
                  </Link>
                </li>
                <li>
                  <Link to="/villas" className={pathname === "/villas" ? "active" : ""}>
                    Villas
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className={pathname === "/about-us" ? "active" : ""}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className={pathname === "/contact-us" ? "active" : ""}>
                    Contact Us
                  </Link>
                </li>
              </ul>
              <div className="d-flex align-items-center">
                <Link href="/" className="btn btn-primary">
                  Login / Resister
                </Link>
                <button type="button" className="nav-toggler" onClick={() => setIsShown((p) => !p)}>
                  <i className="bi bi-list"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <Link to="/" className="logo d-block mb-3">
                {" "}
                <img src={logo} alt="logo" />
              </Link>
              <small>
                JAGAH DEKHO is a well-known business house dealing with the Expansion of Land,
                Infrastructure, Construction, and Associated businesses. We dependably deliver
                entirely furnished homes to our customers. The active aim of our group is to
                build/develop eco-friendly and peaceful holiday/farmhouses which are away from busy
                urban life bit near to daily needed facilities.
              </small>
              <h4 className="mt-3">Download our App</h4>
              <a href="/">
                <img
                  src={playStore}
                  className="bg-white"
                  alt="play store"
                  style={{ borderRadius: 10, maxWidth: 150 }}
                />
              </a>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-box">
                    <img src={home} alt="home" />
                    <div>
                      <h4>Need to sell or buy?</h4>
                      <p>Contact Us to get more details</p>
                      <Link to="contact-us" className="text-primary">
                        Contact Us <i className="bi bi-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h4 className="mb-3">Subscribe for property updates</h4>
                  <form>
                    <div className="d-flex mb-3">
                      <input
                        type="email"
                        placeholder="Enter your email"
                        className="form-control me-2"
                      />
                      <button type="submit" className="btn btn-primary">
                        Send
                      </button>
                    </div>
                    <label>
                      <input type="checkbox" className="form-check-input me-2" />{" "}
                      <span>I agree with the terms & conditions</span>
                    </label>
                  </form>
                </div>
              </div>
              <hr />
              <div className="row footer-links">
                <div className="col-md-3">
                  <h4 onClick={() => handleAccordion("pages")}>
                    Pages{" "}
                    <i
                      class={`bi bi-${
                        openedAccordion === "pages" ? "dash" : "plus"
                      }-circle d-md-none`}
                    />
                  </h4>
                  <ul
                    className={`${openedAccordion === "pages" ? "d-block" : "d-none"} d-md-block`}
                  >
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/">Properties</Link>
                    </li>
                    <li>
                      <Link to="/">Projects</Link>
                    </li>
                    <li>
                      <Link to="/">Villas</Link>
                    </li>
                    <li>
                      <Link to="/">About Us</Link>
                    </li>
                    <li>
                      <Link to="/">Contact Us</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-md-3">
                  <h4 onClick={() => handleAccordion("useful-links")}>
                    Useful Links{" "}
                    <i
                      class={`bi bi-${
                        openedAccordion === "useful-links" ? "dash" : "plus"
                      }-circle d-md-none`}
                    />
                  </h4>
                  <ul
                    className={`${
                      openedAccordion === "useful-links" ? "d-block" : "d-none"
                    } d-md-block`}
                  >
                    <li>
                      <Link to="/">Property for sale</Link>
                    </li>
                    <li>
                      <Link to="/">Property for rent</Link>
                    </li>
                    <li>
                      <Link to="/">Residential Project</Link>
                    </li>
                    <li>
                      <Link to="/">Commercial Project</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <h4 onClick={() => handleAccordion("quick-links")}>
                    Quick Links{" "}
                    <i
                      class={`bi bi-${
                        openedAccordion === "quick-links" ? "dash" : "plus"
                      }-circle d-md-none`}
                    />
                  </h4>
                  <ul
                    className={`${
                      openedAccordion === "quick-links" ? "d-block" : "d-none"
                    } d-md-block`}
                  >
                    <li>
                      <Link to="/">Terms and Conditions</Link>
                    </li>
                    <li>
                      <Link to="/">Privacy</Link>
                    </li>
                    <li>
                      <Link to="/">Cookie Policy</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <h4 onClick={() => handleAccordion("contact-us")}>
                    Contact Us
                    <i
                      class={`bi bi-${
                        openedAccordion === "contact-us" ? "dash" : "plus"
                      }-circle d-md-none`}
                    />
                  </h4>
                  <ul
                    className={`${
                      openedAccordion === "contact-us" ? "d-block" : "d-none"
                    } d-md-block`}
                  >
                    <li>
                      <a href="tel:+91-8879135850">
                        <i className="bi bi-telephone text-primary"></i> +91 8879135850
                      </a>
                      <a href="tel:+91-9920312575">
                        <i className="bi bi-telephone text-primary"></i> +91 9920312575
                      </a>
                    </li>
                    <li>
                      <a href="mailto:jagahdekhoo@gmail.com">
                        <i className="bi bi-envelope text-primary"></i>
                        <small>jagahdekhoo@gmail.com</small>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="bi bi-geo-alt text-primary"></i>Shop No.2 Ramjyoti CHS, N.S.
                        Road Opp. Dhanvantri Hospital Mulund West
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <p style={{ fontSize: 12 }}>
            Disclaimer: JAGAH DEKHO is only an intermediary offering its platform to advertise
            properties of Seller for a Customer/Buyer/User coming on its Website and is not and
            cannot be a party to or privy to or control in any manner any transactions between the
            Seller and the Customer/Buyer/User. All the prices or rates on this Website have been
            extended by various Builder(s)/Developer(s) who have advertised their products. Company
            shall neither be responsible nor liable to mediate or resolve any disputes or
            disagreements between the Customer/Buyer/User and the Seller and both Seller and
            Customer/Buyer/User shall settle all such disputes without involving Company in any
            manner
          </p>
        </div>
        <hr />
        <div className="container footer-bottom">
          <div className="d-flex pb-3 justify-content-between align-items-center">
            <p className="mb-0" style={{ fontSize: 12 }}>
              © 2024 JAGAH DEKHO. All rights reserved.
            </p>
            <div className="d-flex g-4">
              <a href="/">
                <i className="bi bi-rss-fill"></i>
              </a>
              <a href="/">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="/">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="/">
                <i className="bi bi-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default RootLayout;
