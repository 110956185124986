import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import userPlaceholder from "assets/img/user-placeholder.png";
import { RxQuote } from "react-icons/rx";

const Testimonial = () => {
  return (
    <section className="testimonial" style={{ background: "#ffb4001a" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div>
              <span className="sub-title">Our Testimonial</span>
              <h2 className="title">Hear From Our Happy Customers!</h2>
              <p style={{ maxWidth: 400 }}>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odit soluta, sint quae
                modi culpa sapiente!
              </p>
              <div className="d-flex mt-5">
                <div className="me-3 pe-3 border-end">
                  <h4 className="mb-0">2,004</h4>
                  <span>Happy Clients</span>
                </div>
                <div>
                  <h4 className="mb-0">4.9</h4>
                  <span>Overall rating from 1,603 clients</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
              {Array.from({ length: 10 }).map((_, i) => (
                <SwiperSlide key={i}>
                  <div className="testimonial-card">
                    <div className="testimonial-client">
                      <img src={userPlaceholder} alt="client" />
                      <div>
                        <h6 className="mb-0">Divakar Reddy</h6>
                        <small className="text-muted">Mulund (East)</small>
                      </div>
                      <div className="ms-auto text-primary" style={{ fontSize: 50, opacity: 0.2 }}>
                        <RxQuote />
                      </div>
                    </div>
                    <div className="testimonial-text fw-semibold my-4">
                      <p>
                        I am absolutely delighted to have worked with . We will always reckon and
                        recognize the sincere efforts and hard work put in by your team in making
                        our launch a remarkable one.{" "}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
