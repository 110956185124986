import React from "react";
import Modal from "common/components/Modal";
import Select from "common/components/Select";

const Filter = ({ onClose, isOpen }) => {
  return (
    <Modal
      {...{
        onClose,
        isOpen,
        title: "Property Filters",
        footer: (
          <>
            <button type="button" className="btn btn-light">
              Cancel
            </button>
            <button type="button" className="btn btn-primary">
              Apply
            </button>
          </>
        ),
      }}
    >
      <div className="filter-box row gy-4 ">
        <div className="col-lg-6">
          <Select placeholder="Status" options={[{ value: "sale", label: "For Sale" }, { value: "rent", label: "For Rent" }]} />
        </div>
        <div className="col-lg-6">
          <Select placeholder="Type" options={[{ value: "sale", label: "For Sale" }]} />
        </div>
        <div className="col-lg-6">
          <Select placeholder="Location" options={[{ value: "sale", label: "For Sale" }]} />
        </div>
        <div className="col-lg-6">
          <Select placeholder="BHK" options={[{ value: "sale", label: "For Sale" }]} />
        </div>
        <div className="col-lg-6">
          <Select placeholder="Furnishing" options={[{ value: "sale", label: "For Sale" }]} />
        </div>
        <div className="col-lg-3 col-6">
          <Select placeholder="Min Budget" options={[{ value: "sale", label: "For Sale" }]} />
        </div>
        <div className="col-lg-3 col-6">
          <Select placeholder="Max Budget" options={[{ value: "sale", label: "For Sale" }]} />
        </div>
      </div>
    </Modal>
  );
};

export default Filter;
