import React from "react";

const ContactUs = () => {
  return (
    <section>
      <div className="container">
        <h2 className="text-center mb-4">Contact Us</h2>
        <div className="row ">
          <div className="col">
            <form>
              <div className="row gy-4 pt-2">
                <div className="col-lg-6">
                  <input type="text" className="form-control" placeholder="Your name" />
                </div>
                <div className="col-lg-6">
                  <input type="tel" className="form-control" placeholder="Your mobile number" />
                </div>
                <div className="col-12">
                  <input type="email" className="form-control" placeholder="Your email address" />
                </div>
                <div className="col-12">
                  <textarea
                    className="form-control h-auto"
                    placeholder="Message"
                    rows={5}
                  ></textarea>
                </div>
              </div>
              <button type="submit" className="btn btn-primary mt-4">
                Submit
              </button>
            </form>
          </div>
          <div className="col-auto">
            <ul className="contact-details">
              <li>
                <a href="tel:+91-8879135850">
                  <i className="bi bi-telephone text-primary"></i> +91 8879135850
                </a>
                <a href="tel:+91-9920312575">
                  <i className="bi bi-telephone text-primary"></i> +91 9920312575
                </a>
              </li>
              <li>
                <a href="mailto:jagahdekhoo@gmail.com">
                  <i className="bi bi-envelope text-primary"></i>
                  jagahdekhoo@gmail.com
                </a>
              </li>
              <li>
                <a href="/">
                  <i className="bi bi-geo-alt text-primary"></i>Shop No.2 Ramjyoti CHS, N.S. Road
                  Opp. Dhanvantri Hospital Mulund West
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="card mt-4 p-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.805290248911!2d72.94831924679062!3d19.17605993590585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b8fbc9a3eabb%3A0x215fdb8fb50c6ffc!2sRam%20Jyoti%20Building%2C%20NS%20Rd%2C%20Vidya%20Vihar%2C%20Mulund%20West%2C%20Mumbai%2C%20Maharashtra%20400080!5e0!3m2!1sen!2sin!4v1724758719691!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
