import React from "react";

const Modal = ({ title, footer, isOpen, onClose, children }) => {
  return (
    isOpen && (
      <>
        <div className="card modal-box">
          <button type="button" onClick={onClose} className="close-btn">
            <i className="bi bi-x-lg"></i>
          </button>
          {title && (
            <div className="modal-box-header">
              <h5 className="modal-box-title">{title}</h5>
            </div>
          )}
          <div className="modal-box-body">{children}</div>
          {footer && <div className="modal-box-footer">{footer}</div>}
        </div>
        <div className="modal-overlay" onClick={onClose}></div>
      </>
    )
  );
};

export default Modal;
