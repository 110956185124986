import React from "react";

const Categories = () => {
  return (
    <section className="categories">
      <div className="container">
        <div className="title-cover text-center">
          <span className="sub-title">Categories</span>
          <h2 className="title mx-auto">Search By Property Requirement</h2>
        </div>
        <div className="row gy-4">
          <div className="col-lg-3 col-6">
            <div className="card">
              <div className="d-flex justify-content-between align-items-start">
                <i className="bi bi-building"></i>
                <button type="button" className="view-all-btn">
                  View All <i className="bi bi-arrow-right"></i>
                </button>
              </div>
              <h3>Apartment</h3>
              <span className="text-muted">6 properties</span>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="card">
              <div className="d-flex justify-content-between align-items-start">
                <i className="bi bi-shop"></i>
                <button type="button" className="view-all-btn">
                  View All <i className="bi bi-arrow-right"></i>
                </button>
              </div>
              <h3>Commercial</h3>
              <span className="text-muted">6 properties</span>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="card">
              <div className="d-flex justify-content-between align-items-start">
                <i className="bi bi-house"></i>
                <button type="button" className="view-all-btn">
                  View All <i className="bi bi-arrow-right"></i>
                </button>
              </div>
              <h3>Modern Villa</h3>
              <span className="text-muted">6 properties</span>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="card">
              <div className="d-flex justify-content-between align-items-start">
                <i className="bi bi-shop-window"></i>

                <button type="button" className="view-all-btn">
                  View All <i className="bi bi-arrow-right"></i>
                </button>
              </div>
              <h3>Warehouse</h3>
              <span className="text-muted">6 properties</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
