import React, { useState } from "react";
import Filter from "./components/Filter";
import Select from "common/components/Select";
import { LuBedDouble } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { TbLayersIntersect } from "react-icons/tb";
import property1 from "assets/img/property-1.jpeg";

const Properties = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <section>
      <div className="container">
        <div className="row align-items-center mb-4">
          <h2>
            Properties{" "}
            <span className="text-muted" style={{ fontSize: 16 }}>
              (546)
            </span>
          </h2>
          <div className="actions-cover d-flex ms-auto">
            <Select
              defaultValue={{ value: "1", label: "New to Old" }}
              options={[
                { value: "1", label: "New to Old" },
                { value: "2", label: "Old to New" },
              ]}
            />
            <button type="button" className="btn btn-dark ms-3" onClick={() => setIsOpen(true)}>
              <i className="bi bi-funnel"></i>
            </button>
          </div>
        </div>
        <div className="row gy-4">
          {Array.from({ length: 8 }).map((_, i) => (
            <div className="col-lg-3 col-6">
              <div className="card overflow-visible property-card">
                <div className="theme-img property-img n-mtx-3">
                  <button type="button" className={`wish-list-btn ${i === 2 ? "active" : ""}`}>
                    <i className="bi bi-heart"></i>
                  </button>
                  <img src={property1} alt="property1" />
                </div>

                <div className="property-details position-relative">
                  <span className="property-badge">For Sale</span>
                  <h5>750 Sq.Ft. 2 BHK Residential Apartm</h5>
                  <p className="text-muted mb-2">Mulund (West)</p>
                  <span className="price mb-2 d-block">
                    $4,000<span className="sub-text">/month</span>
                  </span>
                  <div className="d-flex property-features">
                    <span>
                      <LuBedDouble /> 3
                    </span>
                    <span>
                      <BiBath /> 2
                    </span>
                    <span>
                      <TbLayersIntersect /> 750 Sq.Ft
                    </span>
                  </div>
                  <button type="button" className="btn btn-primary w-100 mt-3">
                    Contact Dealer
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Filter {...{ onClose, isOpen }} />
    </section>
  );
};

export default Properties;
