import React from "react";

const AboutUs = () => {
  return (
    <section>
        <div className="container">

      <h2>Welcome to JAGAH DEKHO</h2>
      <p>
        JAGAH DEKHO is a well-known business house dealing with the Expansion of Land,
        Infrastructure, Construction, and Associated businesses. We dependably deliver entirely
        furnished homes to our customers. The active aim of our group is to build/develop
        eco-friendly and peaceful holiday/farmhouses which are away from busy urban life bit near to
        daily needed facilities. We have professionally finished and also are doing projects of
        plots, villas, cottages, apartments, etc. in the area of Residential Property Developers,
        Building Construction, & Developers, Luxury Builders Apartment Builder Developer, Indian
        Builders, Developers.
      </p>

      <p>
        Our range of properties includes Residential and commercial Properties created with modular
        kitchens, designer flooring, and nice interiors. Allowable with other vital amenities in
        these units which are made based on relaxed lifestyles & extravagant people.
      </p>

      <h3>Our Establishment</h3>
      <p>
        Since our start, we have been transporting all-embracing consultancy and link services
        dependably and innovatively. We command knowledge in Mumbai, Maharashtra forging an extreme
        deal of planned link with the government agencies. Our services offer expert business deals
        and foster supportive relationships among corporations, foundations, voluntary
        organizations, and government
      </p>

      <h3>Our Services</h3>
      <p>
        As a conspicuous and dependable name in this business domain, we are sufficiently leaving
        the role of Real Estate Agents, Property Dealers Brokers, Estate Dealers, Real Estate
        Realtors, Residential Real Estate Agents, Best Real Estate Property Dealers, and Commercial
        Property Dealers.
      </p>

      <h3>Why us?</h3>
      <ul style={{listStyle: 'disc'}} className="ps-4">
        <li>Equipped with vast data and experienced staff</li>
        <li>Extremely predictions of proficiency for yourself and your expectations.</li>
        <li>Enthusiasm toward designing the marketing requirements</li>
        <li>Working with extremely skilled and trained staff</li>
      </ul>
      </div>
    </section>
  );
};

export default AboutUs;
