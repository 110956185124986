import React from "react";
import Banner from "./components/Banner";
import Categories from "./components/Categories";
import Properties from "./components/Properties";
import Places from "./components/Places";
import Testimonial from "./components/Testimonial";
import Projects from "./components/Projects";
import Villas from "./components/Villas";

const Home = () => {
  return (
    <>
      <Banner />
      <Categories />
      <Properties />
      <Projects />
      <Villas/>
      <Places />
      <Testimonial />
    </>
  );
};

export default Home;
