import React from "react";
import place1 from "assets/img/loc-1.jpg";
import place2 from "assets/img/loc-2.jpg";
import place3 from "assets/img/loc-3.jpg";
import place4 from "assets/img/loc-4.jpg";

const Places = () => {
  return (
    <section className="places">
      <div className="container">
        <div className="title-cover text-center">
          <span className="sub-title">Popular Properties</span>
          <h2 className="title mx-auto">Find Properties in These Cities</h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row gy-4">
              <div className="col-6">
                <div className="theme-img" style={{ paddingTop: "100%" }}>
                  <div className="place-title-cover">
                    <span className="place-sub-title">64 Properties</span>
                    <h3 className="place-title">Mulund (West)</h3>
                  </div>
                  <img src={place1} alt="place1" />
                </div>
              </div>
              <div className="col-6">
                <div className="theme-img" style={{ paddingTop: "100%" }}>
                  <div className="place-title-cover">
                    <span className="place-sub-title">5 Properties</span>
                    <h3 className="place-title">Mulund (East)</h3>
                  </div>
                  <img src={place2} alt="place1" />
                </div>
              </div>
              <div className="col-12">
                <div className="theme-img" style={{ paddingTop: "calc(50% - 12px)" }}>
                  <div className="place-title-cover">
                    <span className="place-sub-title">2 Properties</span>
                    <h3 className="place-title">Kanjur Marg (East)</h3>
                  </div>
                  <img src={place3} alt="place1" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="theme-img" style={{ paddingTop: "100%" }}>
              <div className="place-title-cover">
                <span className="place-sub-title">2 Properties</span>
                <h3 className="place-title">Thane (West)</h3>
              </div>
              <img src={place4} alt="place1" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Places;
