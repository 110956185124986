import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/home";
import RootLayout from "./layouts/RootLayout";
import Properties from "pages/properties";
import Projects from "pages/projects";
import Villas from "pages/villas";
import AboutUs from "pages/about-us";
import ContactUs from "pages/contact-us";
import PropertyDetails from "pages/property-details";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />}></Route>
      <Route path="properties" element={<Properties />}></Route>
      <Route path="property-details/:id" element={<PropertyDetails />}></Route>
      <Route path="projects" element={<Projects />}></Route>
      <Route path="villas" element={<Villas />}></Route>
      <Route path="about-us" element={<AboutUs />}></Route>
      <Route path="contact-us" element={<ContactUs />}></Route>
    </Route>
  )
);
const App = () => {
  return <RouterProvider {...{ router }} />;
};

export default App;
