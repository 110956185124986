import React from "react";
import property1 from "assets/img/property-1.jpeg";
import { LuBedDouble } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { TbLayersIntersect } from "react-icons/tb";

const Properties = () => {
  return (
    <section style={{ background: "#ffb4001a" }}>
      <div className="container">
        <div className="text-center title-cover">
          <span className="sub-title">Explore Properties</span>
          <h2 className="title mx-auto">
            Welcome To Our Luxurious Properties, With All The Conveniences.
          </h2>
        </div>
        <div className="mb-3 d-flex flex-wrap align-items-end tab-bar-cover">
          <div className="tab-bar me-auto">
            <button type="button" className="active">
              All Properties
            </button>
            <button type="button">For Sale</button>
            <button type="button">For Rent</button>
          </div>
        </div>
        <button type="button" className="ms-auto d-block mb-3 text-primary">
          View All <i className="bi bi-arrow-right"></i>
        </button>
        <div className="row gy-4">
          {Array.from({ length: 8 }).map((_, i) => (
            <div className="col-lg-3 col-6">
              <div className="card overflow-visible property-card">
                <div className="theme-img property-img n-mtx-3">
                  <button type="button" className={`wish-list-btn ${i === 2 ? "active" : ""}`}>
                    <i className="bi bi-heart"></i>
                  </button>
                  <img src={property1} alt="property1" />
                </div>

                <div className="property-details position-relative">
                  <span className="property-badge">For Sale</span>
                  <h5>750 Sq.Ft. 2 BHK Residential Apartm</h5>
                  <p className="text-muted mb-2">Mulund (West)</p>
                  <span className="price mb-2 d-block">
                    $4,000<span className="sub-text">/month</span>
                  </span>
                  <div className="d-flex property-features">
                    <span>
                      <LuBedDouble /> 3
                    </span>
                    <span>
                      <BiBath /> 2
                    </span>
                    <span>
                      <TbLayersIntersect /> 750 Sq.Ft
                    </span>
                  </div>
                  <button type="button" className="btn btn-primary w-100 mt-3">
                    Contact Dealer
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Properties;
