import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import property1 from "assets/img/property-1.jpeg";
import { LuBedDouble } from "react-icons/lu";
import { BiBath } from "react-icons/bi";
import { TbLayersIntersect } from "react-icons/tb";

const PropertyDetails = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { id } = useParams();
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="main-swiper"
              >
                {Array.from({ length: 8 }).map((_, i) => (
                  <SwiperSlide>
                    <div className="theme-img">
                      <img src={property1} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                onSwiper={setThumbsSwiper}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="sub-swiper"
              >
                {Array.from({ length: 8 }).map((_, i) => (
                  <SwiperSlide>
                    <div className="theme-img">
                      <img src={property1} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-lg-7">
              <h4>960 Sq.Ft. 3 BHK Residential Apartment for Sale in Mulund (West)</h4>
              <p className="text-muted">Mulund (West) , Mumbai Central Suburbs</p>
              <div className="d-flex mb-4 align-items-center">
                <h3 className="text-primary mb-0">$1,50,000</h3>
                <p className="mb-0 ms-2">EMI: $5000/month</p>
              </div>
              <div className="d-flex  property-features property-features-v2">
                <span>
                  <LuBedDouble /> 3 Bedrooms
                </span>
                <span>
                  <BiBath /> 2 Bathrooms
                </span>
                <span>
                  <TbLayersIntersect /> 750 Sq.Ft
                </span>
              </div>

              <div className="card bg-light mt-4">
                <div className="row  gy-4">
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Area size</span>
                    <p className="fw-bolder mb-0">750 sq.ft</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Developer</span>
                    <p className="fw-bolder mb-0">Jagahdekho Group</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Project</span>
                    <p className="fw-bolder mb-0">Jagahdekho Tower</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Floor</span>
                    <p className="fw-bolder mb-0">5(Out of 8 Floors)</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Transaction type</span>
                    <p className="fw-bolder mb-0">Resale</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Status</span>
                    <p className="fw-bolder mb-0">Ready to Move</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Furnished Status</span>
                    <p className="fw-bolder mb-0">Furnished</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Car parking</span>
                    <p className="fw-bolder mb-0">506 Covered</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-6">
                    <span className="text-muted">Type of Ownership</span>
                    <p className="fw-bolder mb-0">Co-operative Society</p>
                  </div>
                </div>
              </div>

              <button type="button" className="btn btn-primary w-50 d-block mt-4 ">
                Contact Dealer
              </button>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: "#ffb4001a" }}>
        <div className="container">
          <span className="sub-title">Related Properties</span>
          <h2 className="title mb-4">More Related Properties According to Your Search</h2>
          <div className="row gy-4">
            {Array.from({ length: 4 }).map((_, i) => (
              <div className="col-lg-3 col-6">
                <div className="card overflow-visible property-card">
                  <div className="theme-img property-img n-mtx-3">
                    <button type="button" className={`wish-list-btn ${i === 2 ? "active" : ""}`}>
                      <i className="bi bi-heart"></i>
                    </button>
                    <img src={property1} alt="property1" />
                  </div>

                  <div className="property-details position-relative">
                    <span className="property-badge">For Sale</span>
                    <h5>750 Sq.Ft. 2 BHK Residential Apartm</h5>
                    <p className="text-muted mb-2">Mulund (West)</p>
                    <span className="price mb-2 d-block">
                      $4,000<span className="sub-text">/month</span>
                    </span>
                    <div className="d-flex property-features">
                      <span>
                        <LuBedDouble /> 3
                      </span>
                      <span>
                        <BiBath /> 2
                      </span>
                      <span>
                        <TbLayersIntersect /> 750 Sq.Ft
                      </span>
                    </div>
                    <button type="button" className="btn btn-primary w-100 mt-3">
                      Contact Dealer
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyDetails;
