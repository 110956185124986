import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "assets/img/slid-1.jpg";
import slide2 from "assets/img/slid-2.jpg";
import slide3 from "assets/img/slid-3.jpg";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import Select from "common/components/Select";

const swiperConfig = {
  effect: "fade",
  slidesPerView: 1,
  autoplay: {
    delay: 10000,
  },
  pagination: {
    clickable: true,
  },
  loop: true,
  className: "banner-slider",
  modules: [Autoplay, EffectFade, Pagination],
};
const Banner = () => {
  return (
    <section className="banner">
      <Swiper {...swiperConfig}>
        <SwiperSlide>
          <img src={slide1} alt="slide1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide2} alt="slide2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide3} alt="slide3" />
        </SwiperSlide>
      </Swiper>
      <div className="container">
        <div className="banner-content">
          <span className="sub-title">Most Trusted</span>
          <h1 className="title">Perfect Firm For Selling Or Leasing Houses, Flats, And Villas</h1>
          <div className="search-box mt-4">
            <form>
              <div className="banner-form-top">
                <label className="pe-3">
                  <input type="radio" name="for" className="form-check-input me-2" />
                  <span>For Lease</span>
                </label>
                <label className="pe-3">
                  <input type="radio" name="for" className="form-check-input me-2" />
                  <span>For Rent</span>
                </label>
                <label className="pe-3">
                  <input type="radio" name="for" className="form-check-input me-2" />
                  <span>For Sale</span>
                </label>
              </div>
              {/* <Select options={[{ value: "chocolate", label: "Chocolate" }]} /> */}
              <div className="banner-form">
                <select className="form-select">
                  <option value="">BHK</option>
                  <option value="">BHK</option>
                  <option value="">BHK</option>
                </select>
                <select className="form-select">
                  <option value="">Property Type</option>
                  <option value="">Property Type</option>
                  <option value="">Property Type</option>
                </select>
                <select className="form-select">
                  <option value="">Location</option>
                  <option value="">Location</option>
                  <option value="">Location</option>
                </select>
                <button type="submit" className="btn btn-primary">
                  Search Property
                </button>
              </div>
            </form>
          </div>
          <div className="banner-portfolio">
            <span>
              <i className="bi bi-house-fill text-primary"></i> 3,000+ Properties
            </span>
            <span>
              <i className="bi bi-emoji-smile-fill text-primary"></i> 2,004 Happy Clients
            </span>
            <span>
              <i className="bi bi-map-fill text-primary"></i> 7+ Popular Places
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
